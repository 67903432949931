<template>
  <lab-task>
    <lab-sim
      :script-src="scriptSrc"
      lab-name="titration-alpha-exercise-part2"
      v-bind="$attrs"
      v-on="$listeners"
      @allGuidanceComplete="submitResponse"
    />
  </lab-task>
</template>

<script>
import {CompletableSimMixin} from '../../mixins/completable-sim';
import {defineComponent} from '@vue/composition-api';
import LabSim from '../simulations/LabSim.vue';
import LabTask from './labs/LabTask';

export default defineComponent({
  name: 'OttawaTitrationSIMPart2',
  components: {LabSim, LabTask},
  mixins: [CompletableSimMixin()],
  data: () => ({
    scriptSrc: '/simulations/stable/ottawa-titration/stemble-lab.min.js',
  }),
});
</script>
